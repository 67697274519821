$(document).ready(function(){
  login_dropdown();
  watermark('.login');
  watermark('#lb-signup-createAccount','.inputWrapper:not(.noWatermark)');
  watermark('#lb-login','.inputWrapper:not(.noWatermark)');
  localeDropDown();
  preventDoubleSubmit();
});

function preventDoubleSubmit()
{
  $.listen('parsley:form:validated', function (formInstance) {
    // console.log(formInstance.isValid());
    if (formInstance.isValid())
    {
      // console.log(formInstance.$element);
      $(formInstance.$element).find('input[type=submit]').attr('disabled', 'disabled');
    }
  });
}

/**/
$('.lb-signup-createAccount, .js-lb-signup').click(function() {
  $('#lb-signup-createAccount').lightbox();
  return false;
});

$('.lb-login, .js-lb-login').click(function() {
  $('#lb-login').lightbox();
  return false;
});

/**
 * build js-popup based on url passed through data attr/value of html element
 */
$('[class*="js-popup"]').on('click', function(event) {
  var url = $(this).data('popup-url');
  buildPopup(url, event);
});

/**
 * param url defines location for popup window to open
 * event prevents default action - if element clicked is an anchor
 *
 * @param url
 * @param event
 * @param popupWidth
 * @param popupHeight
 */
function buildPopup(url, event, popupHeight, popupWidth)
{
  var pHeight = popupHeight || 800;
  var pWidth = popupWidth || 700;

  event.preventDefault();
  window.open(
    url,
    "popupWindow",
    "width="+ pWidth +", height="+ pHeight +", scrollbars=yes"
  );
}

function login_dropdown()
{
  function reset_layout()
  {
    $('#login-dropdown form').removeClass('login-hidden').hide();
    $('#login-account').show();
    $('#login-forgottenPassword').addClass('login-hidden');
  }
  reset_layout();

  $('#header .menu .login > a').click(function() {
    reset_layout();
    $('#login-dropdown').slideToggle();
    return false;
  });

  $('#login-dropdown a').click(function() {
    $(this).parent().addClass('clicked').stop(true,true).slideUp(function() {
      $('.login-hidden').stop(true,true).slideDown().removeClass('login-hidden');
      $('.clicked').addClass('login-hidden').removeClass('clicked');
    });

    return false;
  });

  $('html').click(function() {
    $('#login-dropdown').slideUp(function() {
      reset_layout();
    });
  });

  $('#login-dropdown').click(function(event) {
    event.stopPropagation();
  });
}

function localeDropDown()
{
  $('#user-locale').find('li').slice(1).click(
    function()
    {
      var locale = $(this).data('locale');
      /*if(window.location.href.indexOf('?') > 0)
      {
        window.location.href = window.location.host + '&l=' + locale;
      }
      else
      {
        window.location.href = window.location.host + '?l=' + locale;
      }*/

      window.location.href = location.protocol +'//'+ window.location.host + '?l=' + locale;
    }
  );
}
